'use client';

import React, { useEffect, useState } from 'react';

import { useOverviewReport, type TOverviewReportSources } from '@shared/api/overview-report';
import { mapTotalsFromBackend } from '@widgets/overview-report/lib/map-totals-from-backend';
import { ReportFilters } from 'src/widgets/overview-report/ui/report-filters';
import { ReportPagination } from 'src/shared/components/report-pagination';
import { useTablePagination } from 'src/shared/hooks/use-table-pagination';
import { mapDateToBackend } from '@shared/mappers/map-date-to-backend';
import { mapSortingOrderToBackend } from '@shared/mappers/map-sorting-order-to-backend';
import { useTableSorting } from 'src/shared/hooks/use-table-sorting';
import { type TDateRangeValue } from '@shared/components/date-range-calendar';
import { mapGroupingTypeToBackend } from '@shared/mappers/map-grouping-options-to-backend';
import { CommonReportTable } from '@shared/components/report-table';
import { mapDataToCellData } from '@widgets/overview-report/lib/map-data-to-cell-data';
import { ReportContainer, ReportPaper } from '@shared/components/report-container';
import { useSetOptionsOnce } from 'src/shared/hooks/use-set-options-once';
import { useRole } from '@entities/roles';
import { useAidList } from '@entities/aid-list';

import {
	overviewReportDefaultData,
	overviewReportDefaultDateRange,
	overviewReportDefaultSortingOrder,
	overviewReportDefaultSortingType,
	overviewReportDefaultTotals,
	overviewReportGroupByOptions,
	overviewReportTableColumns,
	type TOverviewReportColumnId,
} from './model';

export const OverviewReport: React.FC = () => {
	const { role, isManager, isAffiliate } = useRole();
	const [dateRange, setDateRange] = useState<TDateRangeValue>(overviewReportDefaultDateRange);

	const [groupingTypeOptions, setGroupingTypeOptions] = useState<string[]>([
		overviewReportGroupByOptions[0].value,
	]);

	const currentGroupingType = groupingTypeOptions[0];

	const { sorting, handleSortingChange } = useTableSorting<TOverviewReportColumnId>({
		defaultSortingOrder: overviewReportDefaultSortingOrder,
	});

	const { page, setPage, handleChangePage, rowsPerPage, handleChangeRowsPerPage } =
		useTablePagination({
			pageRowsCount: 10,
		});

	const [sourcesOptions, setSourcesOptions] = useState<TOverviewReportSources | undefined>(
		undefined,
	);

	const { aidList, isAidListSuccess, initialAidsSet } = useAidList(isManager);

	const [selectedAidList, setSelectedAidList] = useState<Set<number> | undefined>();

	const [selectedSources, setSelectedSources] = useState<TOverviewReportSources>([]);

	const isQueryEnabled =
		!dateRange.includes(null) &&
		Boolean(role) &&
		(!isManager || (isAidListSuccess && selectedAidList?.size !== 0 && initialAidsSet.size !== 0));

	const {
		data: reportData,
		isLoading,
		isError,
		invalidate,
		fetchStatus,
	} = useOverviewReport(role).useGetOverviewReport(
		{
			params: { groupType: mapGroupingTypeToBackend(currentGroupingType) },
			queries: {
				aids: Array.from(selectedAidList ?? initialAidsSet),
				dateFrom: mapDateToBackend(dateRange[0]),
				dateTo: mapDateToBackend(dateRange[1]),
				orderBy: overviewReportDefaultSortingType,
				orderDirection: mapSortingOrderToBackend(sorting.date),
				sources: selectedSources,
			},
		},
		{
			enabled: isQueryEnabled,
			staleTime: 10_000,
		},
	);

	// Specific case when manager role user has choosen no aids
	const noAidsSelected = aidList && fetchStatus === 'idle' && isLoading;

	const data = reportData?.data ?? overviewReportDefaultData;

	const totals = reportData?.totals ?? overviewReportDefaultTotals;

	const totalCount = data.length;

	useSetOptionsOnce(reportData?.sources, setSourcesOptions);

	useEffect(() => {
		setPage(0);
	}, [reportData, setPage]);

	return (
		<ReportContainer>
			<ReportPaper>
				<ReportFilters
					dateRange={dateRange}
					setDateRange={setDateRange}
					groupingType={groupingTypeOptions}
					setGroupingType={setGroupingTypeOptions}
					sourcesOptions={isAffiliate ? sourcesOptions : undefined}
					selectedSources={selectedSources}
					setSelectedSources={setSelectedSources}
					setSelectedAids={setSelectedAidList}
				/>

				<CommonReportTable<TOverviewReportColumnId>
					data={mapDataToCellData(data, currentGroupingType)}
					totals={mapTotalsFromBackend(totals)}
					page={page}
					rowsPerPage={rowsPerPage}
					sorting={sorting}
					onSortingChange={handleSortingChange}
					columns={overviewReportTableColumns}
					isLoading={isLoading}
					isError={isError}
					invalidate={invalidate}
					isEmpty={noAidsSelected}
				/>
			</ReportPaper>

			<ReportPagination
				totalCount={totalCount}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</ReportContainer>
	);
};
