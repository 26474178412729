import { useCallback } from 'react';

import { CustomDateRangePicker } from '@shared/components/date-range-calendar';
import { DropdownSelect } from '@shared/components/dropdown-select';
import { overviewReportGroupByOptions } from '@widgets/overview-report/model';
import { mapSourcesFromBackend } from '@shared/mappers/map-sources-from-backend';
import {
	DateRangeFilterContainer,
	ReportFiltersContainer,
} from '@shared/components/report-filters-container';
import { AidList } from '@entities/aid-list';

import { type TReportFiltersProps } from './types';

export const ReportFilters: React.FC<TReportFiltersProps> = ({
	dateRange,
	groupingType,
	setDateRange,
	setGroupingType,
	sourcesOptions,
	selectedSources,
	setSelectedSources,
	setSelectedAids,
}) => {
	const setSelectedAidList = useCallback(
		(selectedValue: number[]) => {
			setSelectedAids(new Set(selectedValue));
		},
		[setSelectedAids],
	);

	return (
		<ReportFiltersContainer>
			<DateRangeFilterContainer>
				<CustomDateRangePicker value={dateRange} setValue={setDateRange} />
			</DateRangeFilterContainer>

			<DropdownSelect
				dropdownValue={groupingType}
				onDropdownSelect={setGroupingType}
				options={overviewReportGroupByOptions}
			/>

			{sourcesOptions && (
				<DropdownSelect
					dropdownValue={selectedSources}
					onDropdownSelect={setSelectedSources}
					options={mapSourcesFromBackend(sourcesOptions)}
					isMultiple
					placeholder="Sources"
					innerTitle="Sources"
					resetButton
				/>
			)}

			<AidList onSelect={setSelectedAidList} />
		</ReportFiltersContainer>
	);
};
