import { type TReportGroupingTypeFromBackend } from '@shared/types';

export const mapGroupingTypeToBackend = (type: string): TReportGroupingTypeFromBackend => {
	switch (type) {
		case 'daily':
			return 'by-day';

		case 'weekly':
			return 'by-week';

		case 'monthly':
			return 'by-month';

		case 'yearly':
		default:
			return 'by-year';
	}
};
