import { makeApi } from '@zodios/core';

import { overviewReportParams, overviewReportResponseSchema } from './schemas';

export const affiliateOverviewReportApi = makeApi([
	{
		alias: 'getOverviewReport',
		method: 'get',
		path: '/cabinet/analytics/report/overview/:groupType/',
		response: overviewReportResponseSchema,
		parameters: overviewReportParams,
	},
]);

export const managerOverviewReportApi = makeApi([
	{
		alias: 'getOverviewReport',
		method: 'get',
		path: '/cabinet/analytics/report/affiliates-overview/:groupType/',
		response: overviewReportResponseSchema,
		parameters: overviewReportParams,
	},
]);
