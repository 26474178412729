import { styled } from '@mui/material';
import MuiStack from '@mui/material/Stack';
import MuiBox from '@mui/material/Box';

import { Button as MuiButton } from '@shared/components/button';

export const Box = styled(MuiBox)(({ theme }) => ({
	marginTop: `-${theme.spacing(1.5)}`,
}));

export const Button = styled(MuiButton)(({ theme }) => ({
	border: `1px solid ${theme.palette.custom.datepicker.border}`,
	borderRadius: theme.spacing(0.75),
	padding: theme.spacing(0.875, 2),
}));

export const FakeButton = styled(MuiButton)(({ theme }) => ({
	border: `1px solid ${theme.palette.custom.datepicker.border}`,
	borderRadius: theme.spacing(0.75),
	padding: theme.spacing(0.875, 2),
	pointerEvents: 'none',
}));

export const SubTitle = styled('p')(({ theme }) => ({
	...theme.typography.subtitle2,
	width: '100%',
	flexGrow: 1,
	margin: 0,
	padding: theme.spacing(0, 1, 1.5, 0),
	color: theme.palette.custom.dark.high,
}));

export const ButtonStack = styled(MuiStack)(({ theme }) => ({
	columnGap: theme.spacing(1),
	flexDirection: 'row',
	flexWrap: 'wrap',
	padding: theme.spacing(1.5),
	borderTop: `1px solid ${theme.palette.custom.datepicker.border}`,
	borderBottom: `1px solid ${theme.palette.custom.datepicker.border}`,
	marginLeft: `-${theme.spacing(1)}`,
	marginRight: `-${theme.spacing(1)}`,
	marginBottom: theme.spacing(1.5),
}));
