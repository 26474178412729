import { type TOverviewReportTotals } from '@shared/api/overview-report';
import { type TRowData } from '@shared/components/report-table/types';
import { type TOverviewReportColumnId } from '@widgets/overview-report/model';
import { getAmountFromCents } from '@shared/utils/number';

export const mapTotalsFromBackend = (
	totals: TOverviewReportTotals,
): TRowData<TOverviewReportColumnId> => ({
	id: 'totals',
	cells: {
		date: {
			value: 'Totals',
		},
		formStart: {
			value: totals.formStart.toString(),
		},
		clicks: {
			value: totals.clicks.toString(),
		},
		earnings: {
			value: getAmountFromCents(totals.earnings).toFixed(2),
		},
		epc: {
			value: getAmountFromCents(totals.epc).toFixed(2),
		},
	},
	isWeekend: false,
	isPartialData: false,
});
