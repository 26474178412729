import { SearchField, type TSearchInputProps } from '@shared/components/search-input';

import { type TAidsFilterType } from '../model/types';

import { FakeButton, Button, SubTitle, ButtonStack, Box } from './style';

type TAidsListHeaderProps = {
	filterType: TAidsFilterType;
	setSearchString: TSearchInputProps['setSearchString'];
	searchString: TSearchInputProps['searchString'];
	onChange: (filterType: TAidsFilterType) => void;
};

export const AidListHeader: React.FC<TAidsListHeaderProps> = ({
	filterType,
	onChange,
	searchString,
	setSearchString,
}) => {
	const areAllSelected = filterType === 'all';
	return (
		<Box>
			<SearchField
				size="sm"
				searchString={searchString}
				setSearchString={setSearchString}
				placeholder="Search Affiliate"
			/>
			<ButtonStack>
				<SubTitle>Show </SubTitle>
				<Button variant={areAllSelected ? 'contained' : 'outlined'} onClick={() => onChange('all')}>
					All
				</Button>
				<FakeButton disableElevation variant={!areAllSelected ? 'contained' : 'outlined'}>
					Custom
				</FakeButton>
			</ButtonStack>
		</Box>
	);
};
