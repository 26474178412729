import { type TAffiliateListItem } from '@shared/api/affiliates';
import { type TDropdownSelectProps } from '@shared/components/dropdown-select';

export const mapAidListToDropdownOptions = (
	aidList: TAffiliateListItem[],
): TDropdownSelectProps<number>['options'] =>
	aidList.map(({ id, name, companyName }) => ({
		value: id,
		label: companyName ?? name ?? '',
		secondaryLabel: String(id),
	}));
