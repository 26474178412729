import * as React from 'react';

import { type TOverviewReportData } from '@shared/api/overview-report';
import { type TRowData } from '@shared/components/report-table/types';
import { type TOverviewReportColumnId } from '@widgets/overview-report/model';
import { mapDateFromBackend } from '@widgets/overview-report/lib/map-date-from-backend';
import { DateYearSpan } from '@shared/components/report-table/styles';
import { WarningBadge } from '@shared/components/table-warning-badge/style';
import { getAmountFromCents } from '@shared/utils/number';
import { RefundedCell } from '@shared/components/refunded-cell';

export const mapDataToCellData = (
	data: TOverviewReportData,
	groupingType: string,
): TRowData<TOverviewReportColumnId>[] =>
	data.map((rowData) => ({
		id: rowData.date,
		cells: {
			date: {
				value: rowData.date,
				render: (cellData: string) => {
					let formattedDate: React.ReactNode = mapDateFromBackend(cellData, groupingType);

					if (typeof formattedDate === 'string' && groupingType === 'monthly') {
						const [dateMonth, dateYear] = formattedDate.split(' ');

						formattedDate = (
							<span>
								<span>{dateMonth}</span> <DateYearSpan>{dateYear}</DateYearSpan>
							</span>
						);
					}

					return (
						<>
							{formattedDate}

							{rowData.isPartialData && <WarningBadge>Preliminary data</WarningBadge>}
						</>
					);
				},
			},
			formStart: {
				value: rowData.formStart.toString(),
			},
			clicks: {
				value: rowData.clicks.toString(),
			},
			earnings: {
				value: getAmountFromCents(rowData.earnings).toFixed(2),
				render: (cellData) => {
					const isRefunded = rowData.earnings !== rowData.afterRefund;
					if (!isRefunded) {
						return cellData;
					}

					return (
						<RefundedCell
							beforeRefundAmount={cellData}
							afterRefundAmount={getAmountFromCents(rowData.afterRefund).toFixed(2).toString()}
						/>
					);
				},
			},
			epc: {
				value: getAmountFromCents(rowData.epc).toFixed(2),
			},
		},
		isWeekend: rowData.isWeekend,
		isPartialData: rowData.isPartialData,
	}));
