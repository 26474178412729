import React, { useState, useMemo, useCallback } from 'react';

import { DropdownSelect } from '@shared/components/dropdown-select';
import { useDebouncedValue } from '@shared/hooks/use-debounced-value';

import { type TAidsFilterType } from '../model/types';
import { useAidList } from '../lib/use-aid-list';

import { AidListHeader } from './aid-list-header';

function getPlaceholder(isModernTheme: boolean, areAllSelected: boolean) {
	if (!isModernTheme) {
		return 'AID';
	}

	if (areAllSelected) {
		return 'All affiliates';
	}

	return 'Custom';
}

interface TAidListProps {
	theme?: 'modern' | 'classic';
	onSelect: (selectedAids: number[]) => void;
}
/*
 * TODO: выделил как отдельный компонент, однако он получился statefull
 *  что не очень хорошо и вероятно, требует рефакторинга;
 * Некоторые данные дублируются здесь и в родительском компоненте
 * (selectedAidList например)
 * */

export const AidList: React.FC<TAidListProps> = ({ theme = 'modern', onSelect }) => {
	const [selectedAidList, setSelectedAidList] = useState<Set<number> | undefined>();
	const { initialAidsSet, aidOptions } = useAidList();

	const aidDropdownValue = useMemo(
		() => Array.from(selectedAidList ?? initialAidsSet),
		[initialAidsSet, selectedAidList],
	);

	const isModernTheme = theme === 'modern';

	const areAllSelected = aidOptions?.length === aidDropdownValue.length;

	const {
		value: searchString,
		setValue: setSearchString,
		debouncedValue: debouncedSearchString,
	} = useDebouncedValue<string>('', 300);

	const displayAidOptions = useMemo(
		() =>
			aidOptions?.filter(({ label }) =>
				label.toLowerCase().startsWith(debouncedSearchString.toLowerCase()),
			),
		[aidOptions, debouncedSearchString],
	);

	const handleDropdownSelect = useCallback(
		(selectedValue: number[]) => {
			setSelectedAidList(new Set(selectedValue));
			onSelect(selectedValue);
		},
		[onSelect],
	);

	const handleChangeAidsListHeader = useCallback(
		(type: TAidsFilterType) => {
			if (type === 'all') {
				handleDropdownSelect(displayAidOptions?.map(({ value }) => value) ?? []);
			}
		},
		[displayAidOptions, handleDropdownSelect],
	);

	if (!displayAidOptions) {
		return null;
	}

	const forcedPlaceholder = getPlaceholder(isModernTheme, areAllSelected);

	return (
		<DropdownSelect<number>
			headerElement={
				<AidListHeader
					searchString={searchString}
					setSearchString={setSearchString}
					filterType={areAllSelected ? 'all' : 'custom'}
					onChange={handleChangeAidsListHeader}
				/>
			}
			dropdownValue={aidDropdownValue}
			onDropdownSelect={handleDropdownSelect}
			options={displayAidOptions}
			isMultiple
			iconType={isModernTheme ? 'filter' : 'default'}
			forcedPlaceholder={forcedPlaceholder}
			innerTitle="Affiliates"
			resetButton
		/>
	);
};
