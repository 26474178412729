import { styled } from '@mui/material';

export const WarningBadge = styled('div')(({ theme }) => ({
	...theme.typography.badge,
	display: 'inline-flex',
	flexFlow: 'row nowrap',
	justifyContent: 'center',
	alignItems: 'center',
	padding: `${theme.typography.pxToRem(2)} ${theme.typography.pxToRem(8)}`,
	backgroundColor: theme.palette.custom.warningBadge.bg,
	color: theme.palette.custom.warningBadge.color,
	borderRadius: theme.typography.pxToRem(4),
	marginLeft: theme.typography.pxToRem(8),
}));
