import { Zodios } from '@zodios/core';
import { ZodiosHooks } from '@zodios/react';

import { axiosInstance } from '@shared/api/axios-instance';

import { BASE_URL } from '../constants';

import { affiliatesApi } from './api';

const affiliatesApiClient = new Zodios(BASE_URL, affiliatesApi, { axiosInstance });

export const affiliatesApiHooks = new ZodiosHooks('user', affiliatesApiClient);

export { type TAffiliateListItem } from './types';
