import { addWeeks, format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

import { LA_TIME_ZONE } from '@shared/utils/date';

export const mapDateFromBackend = (dateString: string, groupingType?: string) => {
	const pstDate = toZonedTime(dateString, LA_TIME_ZONE);

	if (groupingType === 'weekly') {
		return `${format(pstDate, `MM/dd/yyyy`)} - ${format(addWeeks(pstDate, 1), `MM/dd/yyyy`)}`;
	}

	if (groupingType === 'monthly') {
		return `${format(pstDate, `LLLL yyyy`)}`;
	}

	if (groupingType === 'yearly') {
		return `${format(pstDate, `yyyy`)}`;
	}

	return format(pstDate, `MM/dd/yyyy`);
};
