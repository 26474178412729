import { useMemo } from 'react';

import { affiliatesApiHooks } from '@shared/api/affiliates';

import { mapAidListToDropdownOptions } from './map-aid-list-to-dropdown-options';

export const useAidList = (enabled: boolean = true) => {
	const { data: aidList, isSuccess: isAidListSuccess } = affiliatesApiHooks.useGetAffiliates(
		undefined,
		{ enabled },
	);

	const initialAidsSet = useMemo(() => new Set(aidList?.map(({ id }) => id) ?? []), [aidList]);
	const aidOptions = useMemo(
		() => (aidList ? mapAidListToDropdownOptions(aidList) : undefined),
		[aidList],
	);

	return { aidList, isAidListSuccess, initialAidsSet, aidOptions };
};
