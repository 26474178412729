import { makeApi } from '@zodios/core';

import { affiliatesResponseSchema } from './schemas';

export const affiliatesApi = makeApi([
	{
		alias: 'getAffiliates',
		method: 'get',
		path: '/cabinet/affiliates/list/',
		response: affiliatesResponseSchema,
	},
]);
