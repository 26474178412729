import { Zodios } from '@zodios/core';
import { type ZodiosHooksInstance, ZodiosHooks } from '@zodios/react';

import { axiosInstance } from '@shared/api/axios-instance';
import { BASE_URL } from '@shared/api/constants';
import { type TUserRole, userRole } from '@shared/types';

import { affiliateOverviewReportApi, managerOverviewReportApi } from './api';

export * from './types';

const getApiClient = (role?: TUserRole) => {
	switch (role) {
		case userRole.manager:
			return managerOverviewReportApi;
		case userRole.affiliate:
		default:
			return affiliateOverviewReportApi;
	}
};

export const useOverviewReport = (
	role?: TUserRole,
): ZodiosHooksInstance<typeof affiliateOverviewReportApi | typeof managerOverviewReportApi> => {
	const apiClient = new Zodios(BASE_URL, getApiClient(role), { axiosInstance });
	return new ZodiosHooks('overviewReport', apiClient);
};
