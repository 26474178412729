import { formatISO, subWeeks } from 'date-fns';

import { type TOverviewReportData, type TOverviewReportTotals } from '@shared/api/overview-report';
import { type TDateRangeValue } from '@shared/components/date-range-calendar';
import { type TReportSorting } from '@shared/components/report-table/types';

import {
	type TOverviewReportColumn,
	type TOverviewReportColumnId,
	type TReportGroupingOptions,
} from './types';

export const overviewReportDefaultDateRange: TDateRangeValue = [
	formatISO(subWeeks(new Date(), 1)),
	formatISO(new Date()),
];

export const overviewReportDefaultData: TOverviewReportData = [];

export const overviewReportDefaultTotals: TOverviewReportTotals = {
	formStart: 0,
	clicks: 0,
	earnings: 0,
	epc: 0,
};

export const overviewReportDefaultSortingOrder: TReportSorting<TOverviewReportColumnId> = {
	date: 'none',
	formStart: 'none',
	clicks: 'none',
	earnings: 'none',
	epc: 'none',
};

export const overviewReportDefaultSortingType = 'date';

export const overviewReportGroupByOptions: TReportGroupingOptions = [
	{ value: 'daily', label: 'Daily' },
	{ value: 'weekly', label: 'Weekly' },
	{ value: 'monthly', label: 'Monthly' },
];

export const overviewReportTableColumns: TOverviewReportColumn[] = [
	{
		id: 'date',
		label: 'Date',
		width: 'auto',
		align: 'left',
		needSorting: true,
	},
	{ id: 'formStart', label: 'Form start', width: 200, align: 'right' },
	{
		id: 'clicks',
		label: 'Clicks',
		width: 200,
		align: 'right',
	},
	{
		id: 'earnings',
		label: 'Revenue, $',
		width: 'auto',
		align: 'right',
	},
	{
		id: 'epc',
		label: 'EPC, $',
		width: 200,
		align: 'right',
	},
];
