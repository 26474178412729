import z from 'zod';
import { makeParameters } from '@zodios/core';

export const overviewReportParams = makeParameters([
	{
		name: 'dateFrom',
		type: 'Query',
		schema: z.string().datetime({ offset: true }),
	},
	{
		name: 'dateTo',
		type: 'Query',
		schema: z.string().datetime({ offset: true }),
	},
	{
		name: 'source',
		type: 'Query',
		schema: z.array(z.string()).optional(),
	},
	{
		name: 'aids',
		type: 'Query',
		schema: z.array(z.number()).optional(),
	},
	{
		name: 'page',
		type: 'Query',
		schema: z.number().optional(),
	},
	{
		name: 'perPage',
		type: 'Query',
		schema: z.number().min(1).max(100).optional(),
	},
	{
		name: 'orderBy',
		type: 'Query',
		schema: z.string().optional(),
	},
	{
		name: 'orderDirection',
		type: 'Query',
		schema: z.union([z.literal('ASC'), z.literal('DESC')]).optional(),
	},
]);

export const overviewReportResponseSchema = z.object({
	data: z.array(
		z.object({
			date: z.string().datetime({ offset: true }),
			formStart: z.number(),
			clicks: z.number(),
			earnings: z.number(),
			epc: z.number(),
			isWeekend: z.boolean(),
			isPartialData: z.boolean(),
			afterRefund: z.number(),
		}),
	),
	sources: z.array(z.string()),
	totals: z.object({
		formStart: z.number(),
		clicks: z.number(),
		earnings: z.number(),
		epc: z.number(),
	}),
});

export const overviewReportErrorResponseSchema = z.object({
	message: z.string(),
});
